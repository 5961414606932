
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast, ImagePreview } from "vant";
import { FeedbackService } from "src/services";
import globalConfig from "src/config/index";

@Component
export default class UserSetting extends Vue
{
    /**
     * form表单
     * @protected
     */
    public formData: any =
    {
        isReply:false,
        comments: [],
        employerMemberId: "",
        memberName: "",
        result: null,
        phone:""
    }

    /**
     * 公司信息
     * @private
     * @returns void
     */
     private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 图片预览
     * @private
     */
    private ImagePreview(arr: Array<string>, index: any): void
    {
       
        ImagePreview({
            images: arr,
            startPosition: index,
        });
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string, id: any): void
    {
        this.$router.push({name: name, query:{id : id + ""}});
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private created(): void
    {
        if(this.$route.query.id)
        {
            this.getFeedbackDetail(this.$route.query.id);
        }
    }

    /**
     * 获取订单列表 
     */
    protected async getFeedbackDetail(id): Promise<any> {
        try {
            let { content: res } = await FeedbackService.instance.getFeedbackDetail(id);
            this.formData = res.data;
        } catch (err) {
            Toast(err);
        }
    }

    /**
     * 更新是否已解决状态
     */
    protected async updateFeedback(bol): Promise<any> {
        try {
            let data =
            {
                id: this.$route.query.id,
                result: bol
            }
            let { content: res } = await FeedbackService.instance.updateFeedback(data);
            
            if(this.$route.query.id)
            {
                this.getFeedbackDetail(this.$route.query.id);
            }
            Toast("提交成功");
        } catch (err) {
            Toast(err);         
        }
    }

}
