
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast, ImagePreview } from "vant";
import { FeedbackService } from "src/services";
import opertion from "./sub/opertion.vue";
import feedbackList from "./sub/list.vue";
import globalConfig from "src/config/index";

@Component({
    components:{
        opertion,
        feedbackList
    }
})
export default class UserSetting extends Vue
{
    /**
     * tab选中
     * @protected
     */
    protected active: any = 0;
    
    /**
     * 模型
     * @protected
     */
    protected dataModel: any =
    {
        id: null,
        content: "",
        isReply: false,
        imgs: [],
        createdTime: "",
        replyContent: "",
        replyImgs: [],
        replyCreatedTime: ""
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 获取未读消息
     * @private
     * @returns any
     */
     private get unReadMessageCount(): any
    {
        return this.$store.state.unReadMessageCount || 0;
    }


    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 上传文件
     * @protected
     * @reyurns Array<any>
     */
    protected uploader: Array<any> = [];

    /**
     * 刷新
     * @protected
     * @reyurns Array<any>
     */
    protected onRefresh()
    {
        this.active = 1;
    }

        

}
