
    import { Component, Vue } from "vue-property-decorator";
    import { Dialog, Toast } from "vant";
    import { locale } from "dayjs";
    import { PrivacyTermService } from "src/services";
    import ServiceAgreement from "src/views/user/privacy/service-agreement.vue";
    import globalConfig from "src/config/index";
    
    @Component({
        components:
        {
            ServiceAgreement
        }
    })
    export default class Privacy extends Vue
    {
        /**
         * 订单id
         * @private
         * @returns void
         */
        private privacyTermId: number = null;

        /**
         * 显示隐私协议
         * @private
         * @returns number
         */
        private showPrivacyDetail: boolean = false;

        /**
         * 获取当前登入企业相关配置
         * @private
         * @returns void
         */
        protected get employerConfig(): any
        {
            let appid = localStorage.getItem("appid");

            let employerConfig = globalConfig.employerConfig[appid];

            return employerConfig;
        }

        /**
         * 服务协议
         * @private
         * @returns void
         */
        private async onAgreement(): Promise<void>
        {
      
            try
            {
                let {content: result} = await PrivacyTermService.instance.checkPrivacyTerm();
                if(result.data && result.data.privacyTermInfo)
                {
                    this.privacyTermId = result.data.privacyTermInfo.id;
                    this.showPrivacyDetail = true;
                }
            }catch(err)
            {
                Toast(JSON.stringify(err));
            }
        
        }

        /**
         * 服务协议及隐私政策跳转
         * @private
         * @returns void
         */
        private onActionPrivacy(): void
        {
            this.showPrivacyDetail = true;
        }

        /**
         * 关闭隐私协议
         * @private
         * @returns void
         */
        private onClosePrivacy(): void
        {
            this.showPrivacyDetail = false;
        }

        /**
         * 登录协议
         * @private
         * @returns void
         */
        private onLoginPrivacy(): void
        {
            this.$router.push({name:'login-privacy'});
        }
        
    }
    