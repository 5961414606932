
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast, ImagePreview } from "vant";
import { FeedbackService } from "src/services";


@Component
export default class FeedbackOpertion extends Vue
{

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 详情
     * @private
     * @returns void
     */
    private onDetail(item: any): any
    {
       this.$router.push({name:"feedbackDetail",query:{id:item.id}});
    }

    /**
     * 获取订单列表 
     */
    protected async loadData(parameter: any): Promise<any> {
        try {
            let { content: res } = await FeedbackService.instance.getFeedbackList(
                Object.assign({}, parameter)
            );
            
            return res.data;
        } catch (err) {
            Toast(err);
        }
    }

}
