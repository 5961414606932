
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast, ImagePreview } from "vant";
import { FeedbackService } from "src/services";
import { isArray } from "util";

@Component
export default class FeedbackOpertion extends Vue
{
    /**
     * 模型
     * @protected
     */
    protected dataModel: any =
    {
        phone: "",
        content:"",
        pictures:[],
    }

    /**
     * 文件列表
     * @protected
     */
    protected fileList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 文件超出大小
     * @private
     * @returns void
     */
    private onOversize(file: any): void
    {
        Toast('图片超出限制大小,请重新调整后上传');
    }

    /**
     * 文件读取完成后的回调函数
     * @private
     * @returns void
     */
    private onAfterRead(file: any): void
    {
        if(Array.isArray(file))
        {
            file.forEach(item=>
            {
                this.dataModel.pictures.push(item.file);
            })
        }
        else
        {
            this.dataModel.pictures.push(file.file);
        }
        
    }

    /**
     * 删除文件
     * @private
     * @returns void
     */
    private async onDeleteFile(file: any, currentFile): Promise<void>
    {
        this.dataModel.pictures.splice(currentFile.index, 1);
    }

    /***
     * 清空手机号
     */
    private clear():void
    {
        this.dataModel.phone = ''
    }

    /**
     * 加载中
     * @protected
     */
    protected loading: boolean = false;

    /**
     * 新增意见反馈 
     */
    protected async addFeedback(): Promise<any> {
        try {
            if(this.loading) return;
            this.loading = true;
            if(this.dataModel.content.length<10)
            {
                Toast("内容不能少于10个字");
                this.loading = false;
                return
            }
            let formData = new FormData();
            formData.append("phone", this.dataModel.phone);
            formData.append("content", this.dataModel.content);
            this.dataModel.pictures.forEach(item=>
            {
                formData.append("pictures", item);
            })
            await FeedbackService.instance.addFeedback(formData);
            this.loading = false;
            Toast("提交成功");
            this.dataModel.phone = "";
            this.dataModel.content = "";
            this.dataModel.pictures = [];
            this.fileList = [];
            this.$emit("ok")
        } catch (err) {
            this.loading = false;
            Toast(err);
        }
    }

    /**
     * 钩子 
     */
    protected created(): void {
        this.dataModel.phone = this.userInfo.phone;
    }

}
