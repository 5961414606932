var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacts",class:{'page-back-head': !(_vm.employerConfig && _vm.employerConfig.hidTitle)}},[(!(_vm.employerConfig && _vm.employerConfig.hidTitle))?_c('u-page-back',{attrs:{"title":"完善个人信息"}}):_vm._e(),_c('van-form',{on:{"submit":_vm.onSubmitContacts}},[_c('van-field',{attrs:{"required":"","label":"姓名","input-align":"right","rules":[
          {required: true, message: '请输入真实姓名',trigger: 'onBlur' },
      ],"error-message-align":"right","placeholder":"请输入真实姓名"},model:{value:(_vm.modelData.memberName),callback:function ($$v) {_vm.$set(_vm.modelData, "memberName", $$v)},expression:"modelData.memberName"}}),_c('van-field',{attrs:{"required":"","input-align":"right","error-message-align":"right","readonly":"","is-link":"","label":"手机号","rules":[
          {required: true, message: '请输入手机号',trigger: 'onBlur' },
          { validator:_vm.validatorPhone, message: '请输入正确手机号',trigger: 'onBlur' },   
      ],"placeholder":"请修改手机号以保证预约顺畅"},on:{"click":_vm.updatePhone},model:{value:(_vm.modelData.phone),callback:function ($$v) {_vm.$set(_vm.modelData, "phone", $$v)},expression:"modelData.phone"}}),_c('van-field',{attrs:{"required":"","label":"证件类型","readonly":true,"input-align":"right","value":_vm.modelData.cardTypeStr,"is-link":"","placeholder":"请选择证件类型","error-message-align":"right","rules":[
          {required: true, message: '请选择证件类型',trigger: 'onChange' },
      ]},on:{"click":function($event){_vm.showIdtypePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showIdtypePicker),callback:function ($$v) {_vm.showIdtypePicker=$$v},expression:"showIdtypePicker"}},[_c('van-picker',{attrs:{"value-key":"description","show-toolbar":"","columns":_vm.idTypeList},on:{"confirm":_vm.onIdTypeConfirm,"cancel":function($event){_vm.showIdtypePicker = false}}})],1),(_vm.modelData.cardType)?_c('van-field',{attrs:{"required":"","error-message-align":"right","input-align":"right","rules":[
          {required: true, message: '请输入证件号码',trigger: 'onBlur' },
          {validator:_vm.validatorCardNo, message: '请输入正确证件号码',trigger: 'onBlur' },
      ],"label":"证件号码","placeholder":"请填写正确的证件号码以保证预约顺畅"},model:{value:(_vm.modelData.cardNo),callback:function ($$v) {_vm.$set(_vm.modelData, "cardNo", $$v)},expression:"modelData.cardNo"}}):_vm._e(),(_vm.modelData.cardType !== 1)?_c('van-field',{attrs:{"required":"","label":"出生日期","input-align":"right","value":_vm.modelData.birthday,"is-link":"","readonly":true,"error-message-align":"right","placeholder":"请选择出生日期","rules":[
          {required: true, message: '请选择出生日期',trigger: 'onChange' },
      ]},on:{"click":function($event){_vm.showDate = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"选择年月日","minDate":_vm.minDate,"maxDate":_vm.maxDate},on:{"confirm":_vm.onDateConfirm,"cancel":_vm.onDateCancel},model:{value:(_vm.birthdayDate),callback:function ($$v) {_vm.birthdayDate=$$v},expression:"birthdayDate"}})],1),(_vm.modelData.cardType !== 1)?_c('van-field',{attrs:{"required":"","label":"性别","readonly":true,"input-align":"right","value":_vm.modelData.sexStr,"is-link":"","placeholder":"请选择性别","error-message-align":"right","rules":[
          {required: true, message: '请选择性别',trigger: 'onChange' },
      ]},on:{"click":function($event){_vm.showSexPicker = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showSexPicker),callback:function ($$v) {_vm.showSexPicker=$$v},expression:"showSexPicker"}},[_c('van-picker',{attrs:{"value-key":"name","show-toolbar":"","columns":_vm.sexList},on:{"confirm":_vm.onSexConfirm,"cancel":function($event){_vm.showSexPicker = false}}})],1),(_vm.isVivo ? _vm.modelData.sex != 1 : true)?_c('van-field',{attrs:{"required":"","label":"婚否","readonly":true,"input-align":"right","value":_vm.modelData.marriageStatusStr,"is-link":"","placeholder":"请选择婚否","error-message-align":"right","rules":[
          {required: true, message: '请选择婚否',trigger: 'onChange' },
      ]},on:{"click":function($event){_vm.showMarriagePicker = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showMarriagePicker),callback:function ($$v) {_vm.showMarriagePicker=$$v},expression:"showMarriagePicker"}},[_c('van-picker',{attrs:{"value-key":"name","show-toolbar":"","columns":_vm.marriageList},on:{"confirm":_vm.onMarriageConfirm,"cancel":function($event){_vm.showMarriagePicker = false}}})],1),(!_vm.isSelf)?_c('van-field',{staticClass:"mt8",attrs:{"error-message-align":"right","rules":[
          {required: true, message: '请选择与本人关系',trigger: 'onChange' },
      ],"input-align":"right","required":"","label":"与本人的关系","readonly":true,"placeholder":"请选择","value":_vm.modelData.familyTypeStr,"is-link":""},on:{"click":_vm.onShowRelation}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showRelationPicker),callback:function ($$v) {_vm.showRelationPicker=$$v},expression:"showRelationPicker"}},[_c('van-picker',{attrs:{"value-key":"description","show-toolbar":"","columns":_vm.relationshipList},on:{"confirm":_vm.onRelationConfirm,"cancel":function($event){_vm.showRelationPicker = false}}})],1),(_vm.isVivo && _vm.modelData.memberType == 1)?[_c('van-field',{staticClass:"mt8",attrs:{"error-message-align":"right","rules":[
            {required: true, message: '请选择区域',trigger: 'onChange' },
        ],"input-align":"right","required":"","label":"办公区域","readonly":true,"placeholder":"请选择","value":_vm.modelData.remark,"is-link":""},on:{"click":_vm.onShowRegion}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showRegionPicker),callback:function ($$v) {_vm.showRegionPicker=$$v},expression:"showRegionPicker"}},[_c('van-picker',{attrs:{"value-key":"name","show-toolbar":"","columns":_vm.regionList},on:{"confirm":_vm.onRegionConfirm,"cancel":function($event){_vm.showRegionPicker = false}}})],1),_c('div',{staticClass:"area-tip"},[_vm._v("注：若参加港湾上门体检请仔细核对办公区域，以便提前于指定地点领取体检单。")])]:[_c('van-field',{staticClass:"mt8 mb8",attrs:{"input-align":"right","type":"textarea","label":"备注","placeholder":"请输入备注信息"},model:{value:(_vm.modelData.remark),callback:function ($$v) {_vm.$set(_vm.modelData, "remark", $$v)},expression:"modelData.remark"}})],(_vm.modelData.canUpdate)?_c('div',{staticClass:"contacts-submit-btn"},[_c('button',{staticClass:"btn btn-block",attrs:{"type":"submit"}},[_vm._v("保存")])]):_vm._e()],2),_c('van-dialog',{attrs:{"show-confirm-button":false,"width":280},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('div',{staticClass:"tip"},[_c('div',{staticClass:"tip-wrap"},[_c('h3',[_vm._v("温馨提示")]),_c('p',[_vm._v("激活成功！后续登录请使用手机号登录，密码为手机号后六位")])]),_c('van-button',{staticClass:"tip-btn",attrs:{"disabled":_vm.time>0},on:{"click":_vm.onAgree}},[_vm._v("我已知晓"),(_vm.time>0)?_c('span',[_vm._v("("+_vm._s(_vm.time)+"s)")]):_vm._e()])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }